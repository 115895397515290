import PropTypes from "prop-types";
import { Badge, Box, Divider, IconButton, List, ListItemButton, ListItemText, ListSubheader, Popover, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Scrollbar from "components/Scrollbar";
import Iconify from "components/Iconify";
import { getNotificationContracts } from "redux/slices/notification-contracts.slice";
import { useEffect, useState } from "react";
import socket from "contexts/Socket"; // Import the centralized socket instance

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, accessToken } = useAuth();

  // Get notification notificationContracts?.data from the Redux store
  const { notificationContracts } = useSelector((state) => state.notificationContract);

  const totalUnRead = notificationContracts?.data?.length || 0;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    // Fetch initial events
    dispatch(getNotificationContracts({ accessToken }));

    // Listen for new events from the socket
    socket.on("contractEvent", () => {
      dispatch(getNotificationContracts({ accessToken }));
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("contractEvent");
    };
  }, [dispatch, accessToken]);
  
  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Contracts
              </ListSubheader>
            }
          >
            {notificationContracts?.data.slice(0, 4).map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                navigate={navigate}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    reference: PropTypes.string,
    status: PropTypes.string,
    // Add other relevant fields from your notification object
  }),
};

function NotificationItem({ notification, navigate, handleClose }) {
  return (
    <ListItemButton
      sx={{
        py: 0.2,
        px: 2.5,
        mt: "1px",
        ...(notification.status === "pending" && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => {
        navigate("/admin/contracts/list");
        handleClose();
      }}
    >
      <ListItemText
        primary={notification?.reference}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            {notification?.status}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
