import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isSameDay } from "date-fns";
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
} from "@mui/material";
import Iconify from "components/Iconify";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationPopover";
import { getEvents } from "redux/slices/eventSlice";
import useAuth from "hooks/useAuth";
import socket from "contexts/Socket"; // Import the centralized socket instance

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { user, accessToken } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.event);

  let filteredEvents = [];

  if (!user?.role?.permissions?.includes("canViewAllCalendar")) {
    filteredEvents = events?.data?.filter((event) => {
      return (
        event.members.includes(user.username) ||
        event.createdBy === user.username
      );
    });
  } else {
    filteredEvents = events?.data;
  }

  const todaysEventsAppointments = filteredEvents?.filter((event) => {
    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);

    return (
      (event.type === "Event" || event.type === "Appointment") &&
      (isSameDay(startDate, new Date()) ||
        isSameDay(endDate, new Date()) ||
        (startDate < new Date() && endDate > new Date()))
    );
  });

  useEffect(() => {
    // Fetch initial events
    dispatch(getEvents({ accessToken }));

    // Listen for new events from the socket
    socket.on("calendarEvent", () => {
      dispatch(getEvents({ accessToken }));
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("calendarEvent");
    };
  }, [dispatch, accessToken]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {user?.role?.permissions?.includes("canViewCalendar") && (
            
              <IconButton
                color="default"
                onClick={() => {
                  navigate("/admin/calendar/calendar2");
                }}
                sx={{ width: 40, height: 40 }}
              >
                <Badge
              badgeContent={todaysEventsAppointments?.length}
              color="error"
            >

                <Iconify icon="eva:calendar-outline" />
            </Badge>
              </IconButton>
          )}

          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}