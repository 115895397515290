import React from "react";
import { Box, Container } from "@mui/material";
import govLogo from "assets/gov-logo.jpg";

const PlaceholderPage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        height: "100vh", // Full viewport height for perfect centering
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0, // Remove container padding
        margin: 0, // Remove container margin
        overflow: "hidden", // Prevent scrolling
      }}
    >
      {/* Logo as Watermark */}
      <Box
        component="img"
        src={govLogo}
        alt="Government Logo"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxWidth: "500px", // Optional: Limit max width for large screens
          height: "auto",
          opacity: 0.1, // Light watermark effect
          pointerEvents: "none", // Prevent interaction with the logo
        }}
      />

      {/* Optional Content */}
      <Box
        sx={{
          textAlign: "center",
          zIndex: 1, // Ensure content appears above the watermark
        }}
      >
        {/* Navigation options or placeholder content */}
      </Box>
    </Container>
  );
};

export default PlaceholderPage;
