import React from 'react';
import { Button, Typography, Container, Grid, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Import an error icon
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const ErrorPage = ({ error }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleReportError = async () => {
    try {
      // Prepare error data
      const errorData = {
        user: user?.username || 'Guest', // Include user info if available
        error: {
          message: error?.message || 'No error message provided',
          stack: error?.stack || 'No stack trace available',
        },
        timestamp: new Date().toISOString(),
        path: window.location.pathname,
      };

      // Send error data to backend API
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/report-error`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(errorData),
      });

      if (!response.ok) {
        throw new Error('Failed to send error report to the server');
      }

      // Redirect logic or refresh
      const redirectTo = user?.role?.permissions?.includes('canViewDashboard')
        ? '/dashboard/app'
        : user?.role?.home_page;

      window.location.reload(); // Force a refresh
    } catch (reportingError) {
      console.error('Error while reporting error:', reportingError);
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ErrorOutlineIcon color="error" style={{ fontSize: 60, marginBottom: 20 }} />
            <Typography variant="h4" gutterBottom>
              Oops! Something went wrong.
            </Typography>
            {error && (
              <Typography variant="subtitle1" gutterBottom>
                Error Details: {error.message}
              </Typography>
            )}
            <Button variant="contained" color="primary" onClick={handleReportError} style={{ marginTop: 20 }}>
              Send Error to Developers
            </Button>
            <span style={{ color: 'gray', marginTop: '4%' }}>If the button doesn't work, refresh the browser!</span>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
